$dark-primary-text: black;
$light-primary-text: white;
// -----------------------------------------------------------------------------------------------------
@use "@angular/material" as mat;
@use "../@fuse/scss/partials/_colors" as fuse;
@use "../@fuse/scss/theme.scss" as fusetheme;

// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------
$fuse-white: (
  500: white,
  contrast: (
    500: $dark-primary-text,
  ),
);

$fuse-black: (
  500: black,
  contrast: (
    500: $light-primary-text,
  ),
);

$fuse-navy: (
  50: #ececee,
  100: #c5c6cb,
  200: #9ea1a9,
  300: #7d818c,
  400: #5c616f,
  500: #3c4252,
  600: #353a48,
  700: #2d323e,
  800: #262933,
  900: #1e2129,
  A100: #c5c6cb,
  A200: #9ea1a9,
  A400: #5c616f,
  A700: #2d323e,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

// Generate Fuse color classes for custom palettes
$custom_palettes: (
  fuse-white: $fuse-white,
  fuse-black: $fuse-black,
  fuse-navy: $fuse-navy,
);

@include fuse.fuse-color-classes($custom_palettes);

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------

// Force the input field font sizes to 16px
// $typography: mat.m2-define-typography-config(
//   $input: mat.m2-define-typography-level(16px, 1.125, 400),
// );

// // Setup the typography
// @include mat.all-component-typographies($typography);

// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------

// Import app and page component themes here to enable theming for them
@import "./layout/components/chat-panel/chat-panel.theme";
@import "./layout/components/toolbar/toolbar.theme";

@import "./main/apps/chat/chat.theme";

// Define a mixin for easier access
@mixin components-theme($theme) {
  // Layout components
  @include chat-panel-theme($theme);
  @include toolbar-theme($theme);
  @include chat-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ GT2 alternative theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes

//$gt2-primary-palette: mat-palette($fuse-navy);
//$gt2-accent-palette: mat-palette($mat-light-blue, 600, 400, 700);
//$gt2-warn-palette: mat-palette($mat-red);
//
//$gt2-palettes: (
//        primary: $gt2-primary-palette,
//        accent: $gt2-accent-palette,
//        warn: $gt2-warn-palette
//);
//
//// Create the Material theme object
//$gt2-theme: mat-light-theme($gt2-primary-palette, $gt2-accent-palette, $gt2-warn-palette);

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$default-primary-palette: mat.m2-define-palette($fuse-navy);
$default-accent-palette: mat.m2-define-palette(
  mat.$m2-light-blue-palette,
  600,
  400,
  700
);
$default-warn-palette: mat.m2-define-palette(mat.$m2-red-palette);

// Create the Material theme object
$theme: mat.m2-define-light-theme(
  $default-primary-palette,
  $default-accent-palette,
  $default-warn-palette
);

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {
  // Create an Angular Material theme from the $theme map
  @include mat.all-component-themes($theme);

  // Apply the theme to the Fuse Core
  @include fusetheme.fuse-core-theme($theme);

  // Apply the theme to the user components
  @include components-theme($theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette,
    // GT2
    gt2-red: mat.$m2-red-palette,
    gt2-blue: mat.$m2-blue-palette,
    gt2-light-blue: mat.$m2-light-blue-palette,
    gt2-pink: mat.$m2-pink-palette,
    gt2-purple: mat.$m2-purple-palette,
    gt2-green: mat.$m2-green-palette,
    gt2-light-green: mat.$m2-light-green-palette,
    gt2-amber: mat.$m2-amber-palette,
    gt2-blue-gray: mat.$m2-blue-gray-palette,
    gt2-blue-grey: mat.$m2-blue-grey-palette,
    gt2-brown: mat.$m2-brown-palette,
    gt2-cyan: mat.$m2-cyan-palette,
    gt2-deep-purple: mat.$m2-deep-purple-palette,
    gt2-indigo: mat.$m2-indigo-palette,
    gt2-lime: mat.$m2-lime-palette,
    gt2-orange: mat.$m2-orange-palette,
    gt2-deep-orange: mat.$m2-deep-orange-palette,
    gt2-teal: mat.$m2-teal-palette,
    gt2-gray: mat.$m2-gray-palette,
    gt2-grey: mat.$m2-grey-palette,
    gt2-yellow: mat.$m2-yellow-palette,
    gt2-edit-button: mat.$m2-amber-palette,
    gt2-create-button: mat.$m2-light-green-palette,
    gt2-save-button: mat.$m2-light-green-palette,
    gt2-cancel-edit-button: mat.$m2-red-palette,
  );

  @include fuse.fuse-color-classes($palettes);

  // GT2 main alternative theme (used for page headers for example)
  $gt2-primary-palette: mat.m2-define-palette(mat.$m2-indigo-palette);
  $gt2-accent-palette: mat.m2-define-palette(
    mat.$m2-light-blue-palette,
    600,
    400,
    700
  );
  $gt2-warn-palette: mat.m2-define-palette(mat.$m2-red-palette);

  // Create the Material theme object
  $gt2-theme: mat.m2-define-light-theme(
    $gt2-primary-palette,
    $gt2-accent-palette,
    $gt2-warn-palette
  );

  .gt2-theme-no-min-width {
    @include mat.all-component-themes($gt2-theme);

    $gt2-palettes: (
      primary: $gt2-primary-palette,
      accent: $gt2-accent-palette,
      warn: $gt2-warn-palette,
    );

    //@include fuse-core-theme($test-app-theme);
    @include fuse.fuse-color-classes($gt2-palettes);
  }

  .gt2-theme {
    $gt2-palettes: (
      primary: $gt2-primary-palette,
      accent: $gt2-accent-palette,
      warn: $gt2-warn-palette,
    );

    //@include fuse-core-theme($test-app-theme);
    @include fuse.fuse-color-classes($gt2-palettes);
    min-width: 1023px;
  }

  //
  $gt2-ext-primary-palette: mat.m2-define-palette(mat.$m2-orange-palette);
  $gt2-ext-accent-palette: mat.m2-define-palette(
    mat.$m2-green-palette,
    600,
    400,
    700
  );
  $gt2-ext-warn-palette: mat.m2-define-palette(mat.$m2-blue-grey-palette);

  // // Create the Material theme object
  $gt2-theme-extended: mat.m2-define-light-theme(
    $gt2-ext-primary-palette,
    $gt2-ext-accent-palette,
    $gt2-ext-warn-palette
  );
  .gt2-theme-extended {
    @include mat.all-component-themes($gt2-theme-extended);

    $gt2-ext-palettes: (
      primary: $gt2-ext-primary-palette,
      accent: $gt2-ext-accent-palette,
      warn: $gt2-ext-warn-palette,
    );

    // //@include fuse-core-theme($test-app-theme);
    @include fuse.fuse-color-classes($gt2-ext-palettes);
    min-width: 1023px;
  }

  // --o NEW
  // Create the Material theme object
  $gt2-ext2-primary-palette: mat.m2-define-palette($fuse-navy);
  $gt2-ext2-accent-palette: mat.m2-define-palette(
    mat.$m2-light-blue-palette,
    600,
    400,
    700
  );
  $gt2-ext2-warn-palette: mat.m2-define-palette(mat.$m2-blue-grey-palette);

  // // Create the Material theme object
  $gt2-theme-extended2: mat.m2-define-light-theme(
    $gt2-ext2-primary-palette,
    $gt2-ext2-accent-palette,
    $gt2-ext2-warn-palette
  );

  .gt2-theme-extended2 {
    @include mat.all-component-themes($gt2-theme-extended2);

    $gt2-ext2-palettes: (
      primary: $gt2-ext2-primary-palette,
      accent: $gt2-ext2-accent-palette,
      warn: $gt2-ext2-warn-palette,
    );

    // //@include fuse-core-theme($test-app-theme);
    @include fuse.fuse-color-classes($gt2-ext2-palettes);
    min-width: 1023px;
  }

  //

  ////////
  // GT2 Order page header alternative theme
  $gt2-order-primary-palette: mat.m2-define-palette(
    mat.$m2-teal-palette
  ); // Proposal
  $gt2-order-accent-palette: mat.m2-define-palette(
    mat.$m2-cyan-palette
  ); // Order
  $gt2-order-warn-palette: mat.m2-define-palette(
    mat.$m2-red-palette
  ); // Invoice

  // Create the Material theme object
  $gt2-order-theme: mat.m2-define-light-theme(
    $gt2-order-primary-palette,
    $gt2-order-accent-palette,
    $gt2-order-warn-palette
  );

  .gt2-order-theme {
    @include mat.all-component-themes($gt2-order-theme);

    $gt2-order-palettes: (
      primary: $gt2-order-primary-palette,
      accent: $gt2-order-accent-palette,
      warn: $gt2-order-warn-palette,
    );

    //@include fuse-core-theme($test-app-theme);
    @include fuse.fuse-color-classes($gt2-order-palettes);
    min-width: 1023px;
  }
  .gt2-order-theme-no-min-width {
    $gt2-order-palettes: (
      primary: $gt2-order-primary-palette,
      accent: $gt2-order-accent-palette,
      warn: $gt2-order-warn-palette,
    );

    //@include fuse-core-theme($test-app-theme);
    @include fuse.fuse-color-classes($gt2-order-palettes);
  }

  // GT2 Organization page header alternative theme
  $gt2-org-primary-palette: mat.m2-define-palette(
    mat.$m2-teal-palette
  ); // Proposal
  $gt2-org-accent-palette: mat.m2-define-palette(mat.$m2-cyan-palette); // Order
  $gt2-org-warn-palette: mat.m2-define-palette(mat.$m2-red-palette); // Invoice

  // Create the Material theme object
  $gt2-org-theme: mat.m2-define-light-theme(
    $gt2-org-primary-palette,
    $gt2-org-accent-palette,
    $gt2-org-warn-palette
  );

  .gt2-org-theme {
    $gt2-org-palettes: (
      primary: $gt2-org-primary-palette,
      accent: $gt2-org-accent-palette,
      warn: $gt2-org-warn-palette,
    );

    //@include fuse-core-theme($test-app-theme);
    @include fuse.fuse-color-classes($gt2-org-palettes);
  }

  ///////

  // GT2 alternative buttons theme
  $gt2-buttons-primary-palette: mat.m2-define-palette($fuse-navy);
  $gt2-buttons-accent-palette: mat.m2-define-palette(
    mat.$m2-light-blue-palette,
    600,
    400,
    700
  );
  $gt2-buttons-warn-palette: mat.m2-define-palette(mat.$m2-red-palette);

  //$gt2-buttons-primary-palette: mat-palette($mat-lime);
  //$gt2-buttons-accent-palette: mat-palette($mat-amber);
  //$gt2-buttons-warn-palette: mat-palette($mat-red);

  // Create the Material theme object
  $gt2-buttons-theme: mat.m2-define-light-theme(
    $gt2-buttons-primary-palette,
    $gt2-buttons-accent-palette,
    $gt2-buttons-warn-palette
  );

  .gt2-buttons-theme {
    $gt2-palettes: (
      primary: $gt2-buttons-primary-palette,
      accent: $gt2-buttons-accent-palette,
      warn: $gt2-buttons-warn-palette,
    );

    //@include fuse-core-theme($test-app-theme);
    @include fuse.fuse-color-classes($gt2-palettes);
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Define a yellow light theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$yellow-light-theme-primary-palette: mat.m2-define-palette(
  $fuse-navy,
  600,
  400,
  700
);
$yellow-light-theme-accent-palette: mat.m2-define-palette(
  mat.$m2-yellow-palette,
  600,
  400,
  700
);
$yellow-light-theme-warn-palette: mat.m2-define-palette(mat.$m2-red-palette);

// Create the Material theme object
$yellow-light-theme: mat.m2-define-light-theme(
  $yellow-light-theme-primary-palette,
  $yellow-light-theme-accent-palette,
  $yellow-light-theme-warn-palette
);

// Add ".theme-yellow-light" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-yellow-light {
  // Generate the Angular Material theme
  @include mat.all-component-themes($yellow-light-theme);

  // Apply the theme to the Fuse Core
  @include fusetheme.fuse-core-theme($yellow-light-theme);

  // Apply the theme to the user components
  @include components-theme($yellow-light-theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $yellow-light-theme-primary-palette,
    accent: $yellow-light-theme-accent-palette,
    warn: $yellow-light-theme-warn-palette,
    // GT2
    gt2-red: mat.$m2-red-palette,
    gt2-blue: mat.$m2-blue-palette,
    gt2-light-blue: mat.$m2-light-blue-palette,
    gt2-pink: mat.$m2-pink-palette,
    gt2-purple: mat.$m2-purple-palette,
    gt2-green: mat.$m2-green-palette,
    gt2-amber: mat.$m2-amber-palette,
    gt2-blue-gray: mat.$m2-blue-gray-palette,
    gt2-blue-grey: mat.$m2-blue-grey-palette,
    gt2-brown: mat.$m2-brown-palette,
    gt2-cyan: mat.$m2-cyan-palette,
    gt2-deep-orange: mat.$m2-deep-orange-palette,
    gt2-deep-purple: mat.$m2-deep-purple-palette,
    gt2-indigo: mat.$m2-indigo-palette,
    gt2-light-green: mat.$m2-light-green-palette,
    gt2-lime: mat.$m2-lime-palette,
    gt2-orange: mat.$m2-orange-palette,
    gt2-teal: mat.$m2-teal-palette,
    gt2-gray: mat.$m2-gray-palette,
    gt2-grey: mat.$m2-grey-palette,
    gt2-yellow: mat.$m2-yellow-palette,
    gt2-edit-button: mat.$m2-amber-palette,
    gt2-create-button: mat.$m2-light-green-palette,
  );

  @include fuse.fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a blue-gray dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$blue-gray-dark-theme-primary-palette: mat.m2-define-palette($fuse-navy);
$blue-gray-dark-theme-accent-palette: mat.m2-define-palette(
  mat.$m2-light-blue-palette,
  600,
  400,
  700
);
$blue-gray-dark-theme-warn-palette: mat.m2-define-palette(mat.$m2-red-palette);

// Create the Material theme object
$blue-gray-dark-theme: mat.m2-define-dark-theme(
  $blue-gray-dark-theme-primary-palette,
  $blue-gray-dark-theme-accent-palette,
  $blue-gray-dark-theme-warn-palette
);

// Add ".theme-blue-gray-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-blue-gray-dark {
  // Generate the Angular Material theme
  @include mat.all-component-themes($blue-gray-dark-theme);

  // Apply the theme to the Fuse Core
  @include fusetheme.fuse-core-theme($blue-gray-dark-theme);

  // Apply the theme to the user components
  @include components-theme($blue-gray-dark-theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $blue-gray-dark-theme-primary-palette,
    accent: $blue-gray-dark-theme-accent-palette,
    warn: $blue-gray-dark-theme-warn-palette,
    // GT2
    gt2-red: mat.$m2-red-palette,
    gt2-blue: mat.$m2-blue-palette,
    gt2-light-blue: mat.$m2-light-blue-palette,
    gt2-pink: mat.$m2-pink-palette,
    gt2-purple: mat.$m2-purple-palette,
    gt2-green: mat.$m2-green-palette,
    gt2-amber: mat.$m2-amber-palette,
    gt2-blue-gray: mat.$m2-blue-gray-palette,
    gt2-blue-grey: mat.$m2-blue-grey-palette,
    gt2-brown: mat.$m2-brown-palette,
    gt2-cyan: mat.$m2-cyan-palette,
    gt2-deep-orange: mat.$m2-deep-orange-palette,
    gt2-deep-purple: mat.$m2-deep-purple-palette,
    gt2-indigo: mat.$m2-indigo-palette,
    gt2-light-green: mat.$m2-light-green-palette,
    gt2-lime: mat.$m2-lime-palette,
    gt2-orange: mat.$m2-orange-palette,
    gt2-teal: mat.$m2-teal-palette,
    gt2-gray: mat.$m2-gray-palette,
    gt2-grey: mat.$m2-grey-palette,
    gt2-yellow: mat.$m2-yellow-palette,
    gt2-edit-button: mat.$m2-amber-palette,
    gt2-create-button: mat.$m2-light-green-palette,
  );

  @include fuse.fuse-color-classes($palettes);
  .mat-form-field.mat-focused .mat-form-field-label {
    color: white !important;
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Define a pink dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.m2-define-palette(mat.$m2-pink-palette);
$pink-dark-theme-accent-palette: mat.m2-define-palette(mat.$m2-pink-palette);
$pink-dark-theme-warn-palette: mat.m2-define-palette(mat.$m2-red-palette);

// Create the Material theme object
$pink-dark-theme: mat.m2-define-dark-theme(
  $pink-dark-theme-primary-palette,
  $pink-dark-theme-accent-palette,
  $pink-dark-theme-warn-palette
);

// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-pink-dark {
  // Generate the Angular Material theme
  @include mat.all-component-themes($pink-dark-theme);

  // Apply the theme to the Fuse Core
  @include fusetheme.fuse-core-theme($pink-dark-theme);

  // Apply the theme to the user components
  @include components-theme($pink-dark-theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $pink-dark-theme-primary-palette,
    accent: $pink-dark-theme-accent-palette,
    warn: $pink-dark-theme-warn-palette,
  );

  @include fuse.fuse-color-classes($palettes);
}
