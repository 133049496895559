$dt-row-odd-color: #e7f5ff;
$create-button-color: #ccf2bf;
$crud-create-card-bgcolor: #e6e6e6;
$primary-color: #039be5;

:root {
  --dt-row-odd-color: #e7f5ff; // Default theme color
  --primary-color: #039be5;
  --bg-colors: #fff;
  --bg-colors-2: #f4f4f4;
  --bg-colors-3: #fbfbfb;
  --text-colors: #000;
  --label-colors: rgba(0, 0, 0, 0.54);
  --bg-img: url("/assets/images/backgrounds/lite-material-bg.jpg") no-repeat;

  --select2-single-height: 45px !important;
}

body.theme-blue-gray-dark {
  --dt-row-odd-color: #404359; // Dark theme color
  --primary-color: #039be5;
  --bg-colors: #35384a;
  --bg-colors-2: #2a2d3b;
  --bg-colors-3: #2a2d3b;
  --text-colors: #fff;
  --clock-face-background-color: #2a2d3b !important;
  --label-colors: rgba(255, 255, 255, 0.7);
  --bg-img: url("/assets/images/backgrounds/dark-material-bg.jpg") no-repeat;
  background: #2a2d3b !important;
  .fuse-search-bar.expanded {
    background-color: #2a2d3b !important;
  }
  .mat-paginator {
    background: #35384a;
  }
  .mat-expansion-panel {
    background: #35384a;
  }
  .mat-card {
    background: #35384a;
  }
  .mat-table {
    background: #35384a;
  }
  .mat-autocomplete-panel {
    background: #35384a;
    color: white;
  }
  .mat-dialog-container {
    background: #35384a;
  }
  .mat-menu-panel {
    background: #35384a;
  }
  .mat-select-panel {
    background: #35384a;
  }
  .mat-option-text {
    color: white;
  }
  .mat-input-element {
    caret-color: white !important;
  }
  .mat-form-field-invalid .mat-input-element {
    caret-color: white !important;
  }
  .mat-mdc-form-field {
    input {
      caret-color: white !important;
    }
  }

  .pac-container {
    background-color: var(--bg-colors) !important;
    color: var(--text-colors) !important;
  }
  .pac-item {
    color: var(--text-colors) !important;
  }
  .pac-item:hover {
    background-color: var(--bg-colors-2) !important;
  }
  .pac-item-query {
    color: var(--text-colors) !important;
  }
  .gt2-save-button {
    background-color: #8bc34a !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .gt2-cancel-edit-button {
    background-color: #f44336 !important;
    color: white !important;
  }
  .gt2-theme .primary {
    background-color: #3f51b5 !important;
    color: white !important;
  }
  .gt2-order-theme {
    .primary {
      background-color: #009688 !important;
      color: white !important;
    }
    .accent-800 {
      background-color: #00838f !important;
      color: white !important;
    }
  }
  .gt2-theme-extended .primary {
    background-color: #ff9800 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .gt2-org-theme .accent {
    background-color: #00bcd4 !important;
  }
  ngx-dropzone {
    background: #35384a;
    color: white;
  }
}

//$button-edit-color: gt2-amber;
//$create-button-color: #CCF2BF;

//$mat-custom2: (
//        50 : #fff9e7,
//        100 : #fff0c2,
//        200 : #ffe699,
//        300 : #ffdb70,
//        400 : #ffd452,
//        500 : #ffcc33,
//        600 : #ffc72e,
//        700 : #ffc027,
//        800 : #ffb920,
//        900 : #ffad14,
//        A100 : #ffffff,
//        A200 : #fffdfb,
//        A400 : #ffeac8,
//        A700 : #ffe1ae,
//        contrast: (
//                50 : #000000,
//                100 : #000000,
//                200 : #000000,
//                300 : #000000,
//                400 : #000000,
//                500 : #000000,
//                600 : #000000,
//                700 : #000000,
//                800 : #000000,
//                900 : #000000,
//                A100 : #000000,
//                A200 : #000000,
//                A400 : #000000,
//                A700 : #000000,
//        )
//);
//
//$custom-gt2-palettes: (
//        header-bg-color: $mat-custom2,
//);

//@include fuse-color-classes($custom-gt2-palettes);

$mat-custom: (
  50: #eceff1,
  100: #cfd8dc,
  200: #b0bec5,
  300: #90a4ae,
  400: #78909c,
  500: #607d8b,
  600: #546e7a,
  700: #455a64,
  800: #37474f,
  900: #263238,
  A100: #cfd8dc,
  A200: #b0bec5,
  A400: #78909c,
  A700: #455a64,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);

.create-button-color-bg {
  //background-color: #CCF2BF;
  //color: #CCF2BF;
  background-color: #ccf2bf !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white {
  color: white;
}
