// Import des fichiers nécessaires
@use "app/app.theme";
@import "@fuse/scss/core";

// Utilitaires de marge et de padding
@for $i from 1 through 3 {
  .gt2-mr-#{$i} {
    margin-right: #{$i}px;
  }
  .gt2-pr-#{$i} {
    padding-right: #{$i}px;
  }
  .gt2-ml-#{$i} {
    margin-left: #{$i}px;
  }
  .gt2-pl-#{$i} {
    padding-left: #{$i}px;
  }
}

// Styles globaux
.note-section {
  background-color: transparent;
}

.underline {
  text-decoration: underline;
}

.white-icon {
  color: white;
}

.scrollable-dialog .mat-dialog-container,
.custom-dialog-container .mat-dialog-container,
.table-dialog-container .mat-dialog-container {
  padding: 0 !important;
}

.report-dialog-container .mat-dialog-container,
.report-dialog-container .mat-dialog-content {
  padding: 0 !important;
  background-color: white !important;
  margin: 0 !important;
}

.report-dialog-container .mat-dialog-title {
  padding: 0 !important;
  margin: 0 !important;
}

// Animation pour les boutons avec spinner
@keyframes btn-spinner {
  to {
    transform: rotate(360deg);
  }
}

.btn-spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: btn-spinner 0.8s linear infinite;
}

// Thèmes et styles dynamiques
.ngx-timepicker,
.input,
.p-inputtext {
  background-color: transparent !important;
  color: var(--text-colors) !important;
}

// Dropdown et Multiselect
.p-dropdown,
.p-multiselect {
  background-color: var(--bg-colors) !important;
  color: var(--text-colors) !important;

  .p-dropdown-label,
  .p-multiselect-label,
  .p-multiselect-trigger {
    background-color: var(--bg-colors) !important;
    color: var(--text-colors) !important;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item,
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    background-color: var(--bg-colors) !important;
    color: var(--text-colors) !important;

    &:hover {
      background-color: var(--bg-colors-2) !important;
      color: var(--text-colors) !important;
    }
  }
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: #007ad9 !important;
  color: white !important;
  svg {
    color: white !important;
  }
}
.p-dropdown-item {
  background-color: var(--bg-colors) !important;
  color: var(--text-colors) !important;
  &:hover {
    background-color: var(--bg-colors-2) !important;
    color: var(--text-colors) !important;
  }
}

// DataTable
.p-datatable {
  .p-datatable-header,
  .p-datatable-thead > tr > th,
  .p-datatable-tbody > tr > td,
  .p-datatable-tfoot > tr > td {
    background-color: var(--bg-colors) !important;
    color: var(--text-colors) !important;
  }
}

// Datepicker personnalisé
.ngx-mat-drp-controls button .mat-button-wrapper,
.ngx-mat-drp-selected-date-label {
  color: var(--text-colors) !important;
}

.ngx-mat-drp-overlay {
  background-color: var(--bg-colors);
}

// Timepicker
.timepicker {
  .timepicker__header,
  .clock-face__clock-hand,
  .clock-face__number > span.active {
    background-color: #3949ab !important;
  }

  .timepicker__body,
  .timepicker__actions {
    background-color: var(--bg-colors) !important;
  }
}

// Dialogs spécifiques
.container-history-panel fuse-sidebar {
  width: 580px !important;
  min-width: 580px !important;
  max-width: 580px !important;
}

.assignments-compose-dialog {
  width: 100%;
  .mat-dialog-container {
    padding: 0;
    .compose-form .mat-form-field {
      width: 100%;
    }
  }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}
.mat-mdc-form-field.mat-focused {
  .mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
  }
}
